<template>
    <div class="licenceApply">
        <div class="submitForm">
            <el-form ref="form" disabled :model="submitForm" label-position="top" label-width="140px">
                <div class="title">老人信息</div>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>姓名： </span>
                            <span>{{ rowItem.userName || '--' }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>联系电话： </span>
                            <span>{{ rowItem.userPhone || '--' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>身份证号： </span>
                            <span>{{ rowItem.cardId || '--' }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>出生日期： </span>
                            <span>{{ rowItem.birthday || '--' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>年龄： </span>
                            <span>{{ rowItem.age || '--' }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>性别： </span>
                            <span>{{ ['未知', '男', '女'][rowItem.sex * 1] || '--' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>所属区划： </span>
                            <span
                                >{{ rowItem.streetName }} <span v-if="rowItem.communityName">/{{ rowItem.communityName }}</span>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>户籍地址： </span>
                            <span>{{ rowItem.residenceAddress }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>现居地址： </span>
                            <span>{{ submitForm.homeAddress || '--' }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>身份类别： </span>
                            <span>{{ ['普通老人', '低保老人', '失能老年人', '低保失能老年人'][rowItem.subsidyType * 1] || '' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>月标准： </span>
                            <span>{{ rowItem.subsidyStandard || '--' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="uploadLine">
                    <el-col :span="4" v-if="submitForm.cardFrontImage" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.cardFrontImage"
                                :src="submitForm.cardFrontImage"
                                @click="$zoomIn([...submitForm.cardFrontImage])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">身份证（人像面） </span>
                        </div>
                    </el-col>
                    <el-col v-if="submitForm.cardBackImage" :span="4" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img v-if="submitForm.cardBackImage" :src="submitForm.cardBackImage" @click="$zoomIn([...submitForm.cardBackImage])" width="200" height="200" alt="" />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">身份证(国徽面) </span>
                        </div>
                    </el-col>
                    <el-col v-if="submitForm.residenceOneselfImage" :span="4" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.residenceOneselfImage"
                                :src="submitForm.residenceOneselfImage"
                                @click="$zoomIn([...submitForm.residenceOneselfImage])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">居民户口簿（本人页）</span>
                        </div>
                    </el-col>
                    <el-col v-if="submitForm.residenceFirstImage" :span="4" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.residenceFirstImage"
                                :src="submitForm.residenceFirstImage"
                                @click="$zoomIn([...submitForm.residenceFirstImage])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">居民户口簿（户主页）</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="uploadLine" v-if="rowItem.subsidyType === 2 || rowItem.subsidyType === 3">
                    <div class="title">失能老年人信息</div>
                    <el-row style="margin: 20px 0">
                        <el-col :span="8">
                            <div>
                                <span>失能类别： </span>
                                <span>{{ ['', '重残失能', '重病失能'][submitForm.disabilityCategory * 1] || '--' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <div v-if="submitForm.disabilityCategory === 1">
                        <el-row style="margin: 20px 0">
                            <el-col :span="8">
                                <div>
                                    <span>残疾类别： </span>
                                    <span>{{ ['', '肢体', '智力', '精神', '视力'][submitForm.handicapType * 1] || '--' }}</span>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <span>残疾等级： </span>
                                    <span>{{ ['', '一级', '二级'][submitForm.handicapGrade * 1] || '--' }}</span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin: 20px 0">
                            <el-col :span="8">
                                <div>
                                    <span>残疾人证号码： </span>
                                    <span>{{ submitForm.handicapCard || '--' }}</span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-col :span="4" v-if="submitForm.handicapImage" style="text-align: center; margin-top: 20px">
                            <el-form-item prop="image">
                                <img
                                    v-if="submitForm.handicapImage"
                                    :src="submitForm.handicapImage"
                                    @click="$zoomIn([...submitForm.handicapImage])"
                                    width="200"
                                    height="200"
                                    alt=""
                                />
                                <div v-else class="imgEmpty">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </el-form-item>
                            <div class="flex-column tip_bottom">
                                <span class="imp">第二代残疾人证 </span>
                            </div>
                        </el-col>
                    </div>
                    <div v-if="submitForm.disabilityCategory === 2">
                        <el-row style="margin: 20px 0">
                            <el-col :span="8">
                                <div>
                                    <span>瘫痪卧床原因： </span>
                                    <span>{{ submitForm.palsyReason || '--' }}</span>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <span>瘫痪卧床时间： </span>
                                    <span>{{ submitForm.palsyTime || '--' }}</span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-col :span="4" v-if="submitForm.palsyImage" style="text-align: center; margin-top: 20px">
                            <el-form-item prop="image">
                                <img
                                    v-if="submitForm.handicapImage"
                                    :src="submitForm.handicapImage"
                                    @click="$zoomIn([...submitForm.handicapImage])"
                                    width="200"
                                    height="200"
                                    alt=""
                                />
                                <div v-else class="imgEmpty">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </el-form-item>
                            <div class="flex-column tip_bottom">
                                <span class="imp">第二代残疾人证 </span>
                            </div>
                        </el-col>
                    </div>
                </el-row>
                <el-row class="uploadLine" v-if="rowItem.subsidyType === 1 || rowItem.subsidyType === 3">
                    <div class="title">低保老年人信息</div>
                    <el-row style="margin: 20px 0">
                        <el-col :span="8">
                            <div>
                                <span>低保类型： </span>
                                <span>{{ ['', '城市低保对象', '农村低保对象', '城市三无人员', '农村五保对象'][submitForm.allowanceType * 1] || '--' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" v-if="submitForm.serviceSubsidyApplication" style="text-align: center; margin-top: 20px">
                            <el-form-item prop="image">
                                <img
                                    v-if="submitForm.serviceSubsidyApplication"
                                    :src="submitForm.serviceSubsidyApplication"
                                    @click="$zoomIn([...submitForm.serviceSubsidyApplication])"
                                    width="200"
                                    height="200"
                                    alt=""
                                />
                                <div v-else class="imgEmpty">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </el-form-item>
                            <div class="flex-column tip_bottom">
                                <span class="imp">低保证（城市特困证） </span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 20px 0">
                        <div style="margin-bottom: 20px" class="title">银行卡信息</div>
                        <el-row style="margin: 20px 0">
                            <el-col :span="8">
                                <div>
                                    <span>银行卡号： </span>
                                    <span>{{ submitForm.bankCard || '--' }}</span>
                                </div>
                                <div style="margin-top: 20px">
                                    <span>持卡人姓名： </span>
                                    <span>{{ submitForm.bankUserName || '--' }}</span>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div style="margin-top: 20px">
                                    <span>开户行： </span>
                                    <span>{{ submitForm.bankDeposit || '--' }}</span>
                                </div>
                                <div style="margin-top: 20px">
                                    <span>与申请人关系： </span>
                                    <span>{{ submitForm.bankRelationship || '--' }}</span>
                                </div>
                            </el-col>
                        </el-row>
                    </el-row>
                    <el-row>
                        <el-col :span="4" v-if="submitForm.bankImage" style="text-align: center; margin-top: 20px">
                            <el-form-item prop="image">
                                <img v-if="submitForm.bankImage" :src="submitForm.bankImage" @click="$zoomIn([...submitForm.bankImage])" width="200" height="200" alt="" />
                                <div v-else class="imgEmpty">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </el-form-item>
                            <div class="flex-column tip_bottom">
                                <span class="imp">银行卡-正面 </span>
                            </div>
                        </el-col>
                        <el-col v-if="submitForm.bankBackImage" :span="4" style="text-align: center; margin-top: 20px">
                            <el-form-item prop="image">
                                <img
                                    v-if="submitForm.bankBackImage"
                                    :src="submitForm.bankBackImage"
                                    @click="$zoomIn([...submitForm.bankBackImage])"
                                    width="200"
                                    height="200"
                                    alt=""
                                />
                                <div v-else class="imgEmpty">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </el-form-item>
                            <div class="flex-column tip_bottom">
                                <span class="imp">银行卡-反面 </span>
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
                <div v-if="submitForm.ifAgent !== 1">
                    <div class="title">委托代理人信息</div>
                    <el-row style="margin-top: 20px">
                        <el-col :span="8">
                            <div>
                                <span>姓名： </span>
                                <span>{{ submitForm.agentUserNam || '--' }}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span>身份证号： </span>
                                <span>{{ submitForm.agentCardId || '--' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="8">
                            <div>
                                <span>联系电话： </span>
                                <span>{{ submitForm.agentPhone || '--' }}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span>性别： </span>
                                <span>{{ submitForm.agentSexText || '--' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="8">
                            <div>
                                <span>与申请人关系： </span>
                                <span>{{ submitForm.agentRelationship || '--' }}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span>现居地址： </span>
                                <span>{{ submitForm.agentAddress || '--' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <div class="btn">
                <el-button style="width: 120px; margin-right: 30px" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getElederDetail } from '@/api/highCollarSubsidy'
export default {
    name: 'LicenceApply',
    data() {
        return {
            submitForm: {
                licenseFiling: '',
                orgDictName: '',
                serviceName: '',
                contactPerson: '',
                contactPhone: '',
                contactAddress: '',
                licenceNumber: '',
                businessLicenseNumber: '',
                serviceSubsidyApplication: '',
                unitRegistration: '',
                businessLicense: '',
                foodBusinessLicense: '',
                otherCertificates: [],
                collectionNumber: '',
                collectionAccount: '',
                bankOfDeposit: '',
                totalBeds: '',
                nursingBeds: '',
                auditRecordVoList: []
            },
            rowItem: {}
        }
    },
    beforeRouteEnter(to, from, next) {
        // to.meta.title = '查看'
        next()
    },
    mounted() {
        this.rowItem = JSON.parse(this.$route.query.rowItem)
        this.detailsData()
    },
    methods: {
        async detailsData() {
            const res = await getElederDetail({ id: this.rowItem.id })
            this.submitForm = res.data
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
}
/deep/ .el-form-item {
    margin-bottom: 10px;
}
.btn {
    margin-top: 30px;
    text-align: center;
}
.licenceApply {
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    .submitForm {
        margin-top: 10px;
        .title {
            font-size: 18px;
            font-weight: bold;
            margin-top: 20px;
        }
        .tip {
            color: #7f7f7f;
            margin-bottom: 10px;
        }
        .tip_bottom {
            line-height: 20px;
        }
        .uploadLine {
            margin-top: 20px;
        }
        .imp::before {
            /* content: '*';
        color: #ff4949;
        margin-right: 4px;*/
        }
    }
    .wf300 {
        width: 300px;
    }
}
.imgEmpty {
    width: 200px;
    height: 200px;
    border: 1px dashed #c0c4cc;
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    margin-top: 10px;
    i {
        font-size: 32px;
        color: #c0c4cc;
        text-align: center;
        line-height: 200px;
    }
}
img {
    cursor: pointer;
}
/*  .tip {
      position: relative;
    }
    .tip:after {
      content: '必须为收款账号对应的收款账户名称';
      position: absolute;
      right: -250px;
      top: 0px;
    }*/
span {
    font-size: 14px !important;
}
</style>
